
import {
    BottomNavigation, BottomNavigationAction, Button, Stack, useMediaQuery
} from "@mui/material";
import {
    ConstructionRounded, ContactPageRounded, DescriptionRounded, LocationOnRounded, Panorama
} from "@mui/icons-material";
import logoInline from '../../assets/icn/logoInlineSvg.svg'
import {Link, useLocation} from "react-router-dom";

const PAGES_DATA = [
    {name: 'Accueil', url: '/', value: 'Home', icn: <DescriptionRounded />},
    {name: 'Photos', url: '/Picture', value: 'Picture', icn: <Panorama />},
    {name: 'Equipements', url: '/Equipment', value: 'Equipment', icn: <ConstructionRounded />},
    {name: 'Alentours', url: '/Around', value: 'Around', icn: <LocationOnRounded/>},
    {name: 'Contact', url: '/Contact', value: 'Contact', icn: <ContactPageRounded />}
];

export default function Header() {
    const isDesktop = useMediaQuery('(min-width:800px)');
    const location = useLocation();

    return (
        <Stack
            component={'header'}
            position={'fixed'} top={0} right={0} left={0} zIndex={1000}
            backgroundColor={'#093a5c'} boxShadow={'0 13px 35px -12px rgba(35,35,35,.7)'}
        >
            <Stack
                flexDirection={'row'}
                justifyContent={isDesktop ? 'space-between' : 'center'}
                alignItems={'center'}
                height={isDesktop ? '90px' : '60px'}
                px={isDesktop ? '70px' : '0'}
            >
                <Link to={'/'} style={{height: '70%'}}>
                    <img src={logoInline} alt={'Logo du site'} height={'100%'}/>
                </Link>

                {isDesktop
                    ?
                    <Stack flexDirection={'row'} gap={2}>
                        {PAGES_DATA.map((page) =>
                            <Link key={'MenuButton' + page.value} to={page.url}>
                                <Button color={location.pathname.toLowerCase() === page.url.toLowerCase() ? 'third' : 'secondary'} variant={'text'}>
                                    {page.name}
                                </Button>
                            </Link>
                        )}
                    </Stack>
                    :
                    <BottomNavigation
                        value={location.pathname.toLowerCase()}
                        sx={{position: 'fixed', width: '100%', height: '60px', zIndex: 100, bottom: 'env(safe-area-inset-bottom)',
                            bgcolor: 'primary.main',
                            '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
                                color: 'secondary.main'
                            },
                            '& .Mui-selected': {
                                '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
                                    color: 'third.main'
                                }
                            }
                        }}
                    >
                        {PAGES_DATA.map((page) =>
                            <Link key={'NavLink ' + page.name} to={page.url} style={{width: '100%'}}>
                                <BottomNavigationAction showLabel key={'MenuButton' + page.value} value={page.url.toLowerCase()} label={page.name} icon={page.icn} sx={{height: '100%', width: '100%'}}/>
                            </Link>
                        )}
                    </BottomNavigation>
                }
            </Stack>
        </Stack>
    );
}