import {
    createTheme,
    ThemeProvider
} from "@mui/material";
import PlayfairDisplay from './fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf';

const colorTheme = createTheme({
    palette: {
        primary: {
            main: '#093a5c'
        },
        secondary: {
            main: '#FFFFFF'
        },
        third: {
            main: '#d5af9c'
        }
    }
});

const laBaraquetteTheme = createTheme(colorTheme, {
    typography: {
        fontFamily: 'PlayfairDisplay, sans-serif'
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'PlayfairDisplay';
                    font-weight: normal;
                    src: local('PlayfairDisplay') local('PlayfairDisplay-Regular') url(${PlayfairDisplay}) format('truetype');
                }
            `
        }
    }
});

export default function LaBaraquetteTheme(props) {
    return (
        <ThemeProvider theme={laBaraquetteTheme}>
            {props.children}
        </ThemeProvider>
    );
}