
import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import kitchen from '../../../assets/img/kitchen.png'
import livingroom from '../../../assets/img/livingroom.jpg'
import bedroom from '../../../assets/img/bedroom.jpg'
import garden from '../../../assets/img/garden.jpg'
import LogoFullSvg from '../../../assets/icn/logoFullSvg.svg'
import PictureSlider from "../../../features/PictureSlider/PictureSlider";
import image5 from "../../../assets/img/5.jpg";
import {BedRounded, DeckRounded, KitchenRounded, LocalParking} from "@mui/icons-material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

export default function Home() {
    const isDesktop = useMediaQuery('(min-width:800px)');

    return (
        <Stack component={'section'} gap={7}>
            <PictureSlider />
            <Stack boxShadow={'0 13px 35px -12px rgba(35,35,35,.2)'} flexDirection={isDesktop ? 'row' : 'column'} alignItems={'center'}>
                {isDesktop &&
                    <>
                        <Stack width={isDesktop ? '40%' : '100%'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
                            <img alt={'LogoTransparentSvg'} src={LogoFullSvg} width={'100%'}/>
                        </Stack>
                        <Divider flexItem orientation="vertical" variant={'middle'} />
                    </>
                }
                <Stack width={isDesktop ? '65%' : '90%'} p={isDesktop ? 4 : 2} gap={3}>
                    <Typography color={'third.main'} fontWeight={'bold'} fontSize={'1.8rem'} textAlign={'center'} fontFamily={'PlayfairDisplay'}>
                        À propos de ce logement
                    </Typography>
                    <Typography color={'primary'} textAlign={'justify'}>
                        La "Baraquette", un havre de paix au cœur de la verdure, à proximité à pied des plages, de la
                        ville, ses commerces, ses Halles.
                        <br/>
                        <br/>Le logement Studio neuf avec cuisine toute équipée, chambre indépendante avec claustra amovible
                        qui vous isolera de la partie jour. Deux jardins à votre disposition, un face à la porte
                        d'entrée
                        et l'autre en hauteur avec vue sur Sète. Entrée indépendante avec parking devant la
                        "Baraquette".
                    </Typography>
                </Stack>
            </Stack>

            <Stack boxShadow={'0 13px 35px -12px rgba(35,35,35,.2)'} flexDirection={isDesktop ? 'row' : 'column-reverse'} alignItems={'center'}>
                <Stack width={isDesktop ? '65%' : '90%'} p={isDesktop ? 4 : 2} gap={2}>
                    <Typography color={'primary'} textAlign={'justify'}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim :
                    </Typography>
                    <List dense={true}>
                        <ListItem>
                            <ListItemIcon><BedRounded color='primary' fontSize='small'/></ListItemIcon>
                            <ListItemText primary="2 Personnes"/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><KitchenRounded color='primary' fontSize='small'/></ListItemIcon>
                            <ListItemText primary="Cuisine entièrement équipée"/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><DeckRounded color='primary' fontSize='small'/></ListItemIcon>
                            <ListItemText primary="2 Terrasses"/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><LocalParking color='primary' fontSize='small'/></ListItemIcon>
                            <ListItemText primary="1 place de Parking disponible"/>
                        </ListItem>
                    </List>
                    <Typography color={'third.main'} fontWeight={'bold'} fontSize={'1.3rem'} textAlign={'center'}>
                        Voir plus...
                    </Typography>
                </Stack>
                <ImageList sx={{width: isDesktop ? '35%' : '100%', margin: 0}} cols={2}>
                    <ImageListItem>
                        <img srcSet={kitchen} src={kitchen} alt={'Cuisne'} loading="lazy"/>
                    </ImageListItem>
                    <ImageListItem>
                        <img srcSet={garden} src={garden} alt={'garden'} loading="lazy"/>
                    </ImageListItem>
                    <ImageListItem>
                        <img srcSet={bedroom} src={bedroom} alt={'bedroom'} loading="lazy"/>
                    </ImageListItem>
                    <ImageListItem>
                        <img srcSet={livingroom} src={livingroom} alt={'livingroom'} loading="lazy"/>
                    </ImageListItem>
                </ImageList>
            </Stack>

            <Stack boxShadow={'0 13px 35px -12px rgba(35,35,35,.2)'} flexDirection={isDesktop ? 'row' : 'column'} alignItems={'center'}>
                <Stack width={isDesktop ? '35%' : '100%'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
                    <img alt={'LogoTransparentSvg'} src={image5} width={'100%'}/>
                </Stack>
                <Stack width={isDesktop ? '65%' : '90%'} p={isDesktop ? 4 : 2} gap={3}>
                    <Typography color={'primary'} textAlign={'justify'}>
                        Venez vous ressourcer et découvrir la région, piste cyclable à moins de 2 mn qui vous offre la
                        possibilité de faire le tour de l'étang en passant par Bouzigues et ses huîtres, Balaruc et ses
                        thermes, Sète l'île singulière, Agde et ses plages.
                        <br/>
                        <br/>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua.
                    </Typography>
                    <Typography color={'third.main'} fontWeight={'bold'} fontSize={'1.3rem'} textAlign={'center'}>
                        En savoir plus...
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}