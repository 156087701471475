

export const mapData = [
    {
        value: 0, type:  'visit', name: 'Loupian : la villa Gallo romaine', bikeAccess: true, icon: 'museum' , gps: [43.439936, 3.614363], zoom: 15, center: [43.437607, 3.610105],
        description: 'Laissez-vous envoûter par son l\'élégance intemporelle, où l\'histoire prend vie sous le soleil méditerranéen. Explorez ses mosaïque bien conservés et découvrez l\'héritage fascinant de l’Antiquité du IIe siècle.'
    },
    {
        value: 1, type:  'visit', name: 'Montpellier', bikeAccess: false, icon: 'city' , gps: [43.608890, 3.876572], zoom: 10, center: [43.528515, 3.734880],
        description: 'Marchez sur les pas des pionniers de la médecine à la plus vieille faculté d\'Europe, puis respirez la tranquillité du parc du Peyrou, offrant des vues panoramiques sur la ville. Une expérience exceptionnelle entre savoir ancestral et élégance urbaine, et nombreuses Églises pour voyager aux fils des siècles.'
    },
    {
        value: 2, type:  'visit', name: 'Agde', bikeAccess: false, icon: 'city' , gps: [43.307782, 3.476832], zoom: 10, center: [43.381782, 3.540774],
        description: 'Une vieille ville aux pierres volcaniques. Embarquez pour des balades apaisantes le long du Canal du Midi en péniche ou à pied.'
    },
    {
        value: 3, type:  'visit', name: 'Béziers', bikeAccess: false, icon: 'city' , gps: [43.342603, 3.216546], zoom: 10, center: [43.396474, 3.407800],
        description: 'Explorez les arènes emblématiques de la ville et les 9 écluses majestueuses du Canal du Midi pour une journée agréable.'
    },
    {
        value: 4, type:  'visit', name: 'Bouzigues', bikeAccess: true, icon: 'city', gps: [43.449256, 3.654225], zoom: 13, center: [43.441344, 3.629912],
        description: 'Embarquez pour une balade sereine le long des rives de l\'étang de Bouzigues, où la nature tranquille se mêle aux reflets envoûtants. Une balade paisible vous attend, que vous pourrez conclure dans un restaurant de dégustation de fruit de mer au bord de l’eau. '
    },
    {
        value: 5, type:  'visit', name: 'Pézenas', bikeAccess: false, icon: 'city', gps: [43.461322, 3.422846], zoom: 11, center: [43.453772, 3.513915],
        description: 'Marchez sur les traces de Molière à Pézenas, où le théâtre classique et l\'histoire se mêlent dans un décor d\'antan. Flânez dans les ruelles, visitez les ateliers d\'artisans talentueux et plongez dans un univers où le passé et le présent se rejoignent.'
    },
    {
        value: 6, type:  'visit', name: 'Sète', bikeAccess: true, icon: 'city', gps: [43.407170, 3.700381], zoom: 12, center: [43.422235, 3.657855],
        description: 'Une évasion enchanteresse entre mer et étang, avec le majestueux Mont Saint-Clair comme toile de fond. Explorez des plages dorées, vibrez avec les joutes nautiques, et plongez dans l\'atmosphère enivrante de la Fête de la Saint-Louis. Une expérience qui éveillera tous vos sens dans cette perle de la Méditerranée.'
    },
    {
        value: 7, type:  'visit', name: 'Lac du Salagou', bikeAccess: false, icon: 'water', gps: [43.656727, 3.372111], zoom: 9, center: [43.551814, 3.478541],
        description: 'Randonner sur des roches rouges pour finir par se baigner dans les rivière attenante ou dans le lac.'
    },
    {
        value: 8, type:  'visit', name: 'St Guilhem du désert', bikeAccess: false, icon: 'museum', gps: [43.734103, 3.548785], zoom: 9, center: [43.580546, 3.568560],
        description: 'Une évasion hors du temps au cœur de la nature. Explorez les ruelles pittoresques de ce village médiéval, admirez l\'abbaye classée au patrimoine mondial de l\'UNESCO, et découvrez les randonnées spectaculaires qui vous mèneront à des points de vue à couper le souffle. Rafraîchissez vous au pont du diable sur votre retours.'
    },


    {
        value: 0, type:  'eat', name: 'Loupian : Les Jardins du Pélican', bikeAccess: true, icon: 'grocery' , gps: [43.448746, 3.611701], zoom: 13, center: [43.442069, 3.609911],
        description: 'Vend sa production locale de fruit et légumes de saison'
    },
    {
        value: 1, type:  'eat', name: 'Mèze : Le Grazié', bikeAccess: true, icon: 'restaurant', gps: [43.423588, 3.607123], zoom: 14, center: [43.428718, 3.605153],
        description: 'Spécialité Italienne sur le port de Mèze.'
    },
    {
        value: 2, type:  'eat', name: 'Mèze : Le Bistro Del Mar', bikeAccess: true, icon: 'restaurant', gps: [43.423665, 3.607125], zoom: 14, center: [43.428734, 3.605625],
        description: 'Restaurant de viande et poisson sur le port de Mèze.'
    },
    {
        value: 3, type:  'eat', name: 'Mèze : Glace des Sables', bikeAccess: true, icon: 'icecream', gps: [43.422828, 3.607041], zoom: 14, center: [43.428641, 3.604981],
        description: 'Glacier artisanal sur le bord de l’étang à Mèze.'
    },
    {
        value: 4, type:  'eat', name: 'Mèze : Le ZEST, Atelier and Co, l’Ambre de thau, le Mas Soulas', bikeAccess: true, icon: 'restaurant', gps: [43.436436, 3.624045], zoom: 14, center: [43.434856, 3.615574],
        description:  'Dégustez la production des ostréicoles au bord de l’Étang de Thau, huitres et moules, crues et cuites, avec une vue imprenable.'
    },
    {
        value: 5, type:  'eat', name: 'Mèze : Marché aux fruits et légumes', bikeAccess: true, icon: 'grocery', gps: [43.426260, 3.607045], zoom: 14, center: [43.430667, 3.606961],
        description:  'Jeudi et dimanche matin.'
    },


    {
        value: 0, type:  'hobbies', name: 'Mèze : Cinéma le Taurus', bikeAccess: true, icon: 'movie' , gps: [43.419850, 3.600699], zoom: 13, center: [43.427204, 3.603879],
        description: 'Une petite salle familiale qui vous permettra de vous échappé les jours de pluie. Juste à coté de l’étang.'
    },
    {
        value: 1, type:  'hobbies', name: 'Mèze : Marché aux puces', bikeAccess: true, icon: 'grocery', gps: [43.425434, 3.606742], zoom: 14, center: [43.430109, 3.606262],
        description: 'samedi matin'
    },
    {
        value: 2, type:  'hobbies', name: 'Marché de Pézenas', bikeAccess: false, icon: 'grocery', gps: [43.459930, 3.422472], zoom: 10, center: [43.454395, 3.516038],
        description: 'Le samedi toute la journée'
    },
    {
        value: 3, type:  'hobbies', name: 'Festival de Thau', bikeAccess: true, icon: 'festival', gps: [43.422140, 3.605184], zoom: 14, center: [43.428658, 3.605137],
        description: 'Plongez dans l\'univers envoûtant du Festival de Thau, où la musique, la culture et l\'art se rejoignent au bord de l\'étang. Une célébration vibrante qui fait danser les étoiles et résonne dans votre cœur.'
    },
    {
        value: 4, type:  'hobbies', name: 'Le tours de l’étang de Thau à vélo', bikeAccess: true, icon: 'water', gps: [43.395286, 3.608610], zoom: 11, center: [43.395286, 3.608610],
        description: '(60km le tours complet) à quelques pas de la barquette vous trouverez la piste cyclable, des location de vélo électrique ou non sont disponible à Mèze'
    },
]