
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './utilities/reportWebVitals';
import LaBaraquetteTheme from "./assets/muiTheme";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Header from "./components/Header/Header";
import Home from "./components/Pages/Home/Home";
import Around from "./components/Pages/Around/Around";
import Picture from "./components/Pages/Picture/Picture";
import Equipment from "./components/Pages/Equipment/Equipment";
import Contact from "./components/Pages/Contact/Contact";
import PageNotFound from "./components/Pages/PageNotFound/PageNotFound";
import './assets/css/global.css';
import ScrollToTop from "./features/ScrollToTop/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <LaBaraquetteTheme>
          <Router>
              <Header />
              <main style={{ maxWidth: '1024px', marginBottom: '50px', marginInline: 'auto' }}>
                  <ScrollToTop />
                  <Routes>
                      <Route path='*' element={<PageNotFound />} />
                      <Route path={'/'} element={<Home />} />
                      <Route path={'/Picture'} element={<Picture />} />
                      <Route path={'/Equipment'} element={<Equipment />} />
                      <Route path={'/Around'} element={<Around />} />
                      <Route path={'/Contact'} element={<Contact />} />
                  </Routes>
              </main>
          </Router>
      </LaBaraquetteTheme>
  </React.StrictMode>
);

reportWebVitals(console.log);