
import {useEffect, useMemo, useState} from 'react';
import {Dialog, Stack, useMediaQuery} from "@mui/material";
import {ArrowBackIosRounded, ArrowForwardIosRounded} from "@mui/icons-material";

export default function ViewPicture({ close, selectedImg, imgList }) {
    const isDesktop = useMediaQuery('(min-width:800px)');
    const [current, setCurrent] = useState(1);
    const currentImage = useMemo(() => imgList[current - 1], [current, imgList]);
    const length = imgList.length + 1;

    useEffect(() => {
        setCurrent(selectedImg)
    }, [selectedImg]);

    const ButtonStyle = {
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: 'black',
        borderRadius: '10px',
        padding: '5px',
        '&:hover': {backgroundColor: 'transparent'}
    };

    return (
        <Dialog
            open={!!selectedImg} onClose={close} p={0} m={0} width={'100%'} sx={{borderRadius: 0, backgroundColor: 'transparent'}}
            PaperProps={{sx:{maxWidth: "none", maxHeight: "none", backgroundColor: 'transparent', boxShadow: 'none'}}}
        >
            <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
                <Stack left={0} sx={ButtonStyle} onClick={() => setCurrent(current === 1 ? length - 1 : current - 1)}>
                    <ArrowBackIosRounded sx={{zIndex: 100, fontSize: isDesktop ? '3rem' : '1.5rem', color: 'white'}} />
                </Stack>
                <Stack
                    alignItems={'center'}
                    justifyContent={'center'}
                    height={isDesktop ? '90dvh' : 300}
                    width={isDesktop ? '50dvw' : '90dvw'}
                    backgroundColor={'black'}
                >
                    {currentImage &&
                        <img src={currentImage.img} alt={currentImage.title} style={{maxWidth: '100%', width: 'auto', maxHeight: '100%', height: 'auto'}}/>
                    }
                </Stack>
                <Stack right={0} sx={ButtonStyle} onClick={() => setCurrent(current === length - 1 ? 1 : current + 1)}>
                    <ArrowForwardIosRounded sx={{zIndex: 100, fontSize: isDesktop ? '3rem' : '1.5rem', color: 'white'}} />
                </Stack>
            </Stack>
        </Dialog>
    );
}